<template>
  <div class="overview">
    <ADSectionTitle
      data-cy="advica-details-overview"
      :title="$t('advica.details_sections.how_it_works')"
      class="mb-5"
    />
    <div class="pb-4 pb-md-5">
      <h3 class="mb-4">{{ $t('advica.details_sections.how_it_works') }}</h3>
      <vue-markdown
        class="size16-weight400 mb-5"
        v-if="Boolean(howItWorks)"
        :source="howItWorks"
      />
      <v-skeleton-loader
        class="py-3"
        v-if="isLoading"
        max-width="400"
        type="text"
      />
      <v-divider />
    </div>
    <div>
      <h3 data-cy="advica-details-benefits" class="mb-3">
        {{ $t('advica.details_sections.benefits') }}
      </h3>
      <vue-markdown v-if="Boolean(benefits)" :source="benefits" class="ml-2" />
      <v-skeleton-loader
        class="py-3"
        v-if="isLoading"
        max-width="400"
        type="text"
      />
    </div>
  </div>
</template>

<script>
import ADSectionTitle from './ADSectionTitle';

export default {
  name: 'ADOverview',
  components: { ADSectionTitle },
  props: {
    data: {
      type: String,
      default: '',
    },
    isLoading: {
      type: Boolean,
      default: false,
    },
    howItWorks: {
      type: String,
      default: '',
    },
    benefits: {
      type: String,
      default: '',
    },
  },
};
</script>
