<template>
  <h2 class="section-title">
    {{ title }}
  </h2>
</template>

<script>
export default {
  name: 'ADSectionTitle',
  props: {
    title: {
      type: String,
      default: function() {
        return this.$t('advica.details_sections.section_title');
      },
    },
  },
};
</script>

<style scoped lang="scss">
.section-title {
  position: relative;
  padding-top: 10px;
  color: var(--v-grey8-base);
  @include font-size(18, 27, 300);

  @media (min-width: map-get($grid-breakpoints, md)) {
    @include font-size(20, 30, 300);
  }

  &:before {
    content: '';
    width: 60px;
    height: 2px;
    position: absolute;
    top: 0;
    left: 0;
    border-radius: 2px;
    background-color: var(--v-primary-base);
  }
}
</style>
