<template>
  <div class="highlights">
    <ADSectionTitle
      :title="$t('advica.menu.highlights')"
      class="mb-5 mb-md-8"
    />
    <div tag="ul" class="d-flex flex-wrap py-0">
      <v-col
        cols="12"
        sm="6"
        tag="li"
        class="highlights-item pa-0 pr-10 mb-3 mb-md-5 d-flex align-center"
        v-if="Support"
      >
        <!-- don't move svg to v-img because usage of a global variable for color -->
        <svg
          width="23"
          height="23"
          viewBox="0 0 23 23"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            d="M14.0501 5C15.0268 5.19057 15.9244 5.66826 16.6281 6.37194C17.3318 7.07561 17.8095 7.97326 18.0001 8.95M14.0501 1C16.0793 1.22544 17.9716 2.13417 19.4163 3.57701C20.8609 5.01984 21.7721 6.91101 22.0001 8.94M21.0001 16.92V19.92C21.0012 20.1985 20.9441 20.4742 20.8326 20.7293C20.721 20.9845 20.5574 21.2136 20.3521 21.4019C20.1469 21.5901 19.9046 21.7335 19.6408 21.8227C19.377 21.9119 19.0974 21.9451 18.8201 21.92C15.7429 21.5856 12.7871 20.5341 10.1901 18.85C7.77388 17.3147 5.72539 15.2662 4.19006 12.85C2.50003 10.2412 1.4483 7.27099 1.12006 4.18C1.09507 3.90347 1.12793 3.62476 1.21656 3.36162C1.30518 3.09849 1.44763 2.85669 1.63482 2.65162C1.82202 2.44655 2.04986 2.28271 2.30385 2.17052C2.55783 2.05833 2.8324 2.00026 3.11006 2H6.11006C6.59536 1.99522 7.06585 2.16708 7.43382 2.48353C7.80179 2.79999 8.04213 3.23945 8.11005 3.72C8.23668 4.68007 8.47151 5.62273 8.81006 6.53C8.9446 6.88792 8.97372 7.27691 8.89396 7.65088C8.81421 8.02485 8.62892 8.36811 8.36005 8.64L7.09006 9.91C8.51361 12.4135 10.5865 14.4864 13.0901 15.91L14.3601 14.64C14.6319 14.3711 14.9752 14.1858 15.3492 14.1061C15.7231 14.0263 16.1121 14.0555 16.4701 14.19C17.3773 14.5286 18.32 14.7634 19.2801 14.89C19.7658 14.9585 20.2095 15.2032 20.5266 15.5775C20.8437 15.9518 21.0122 16.4296 21.0001 16.92Z"
            style="stroke: var(--v-primary-base)"
            stroke-width="2"
            stroke-linecap="round"
            stroke-linejoin="round"
          />
        </svg>
        {{ Support }} {{ $t('advica.callouts.support') }}
      </v-col>
      <v-col
        cols="12"
        sm="6"
        tag="li"
        class="highlights-item pa-0 pr-10 mb-3 mb-md-5 d-flex align-center"
        v-if="Languages"
      >
        <!-- don't move svg to v-img because usage of a global variable for color -->
        <svg
          width="20"
          height="20"
          viewBox="0 0 20 20"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            d="M19 10C19 12.3869 18.0518 14.6761 16.364 16.364C14.6761 18.0518 12.3869 19 10 19M19 10C19 7.61305 18.0518 5.32387 16.364 3.63604C14.6761 1.94821 12.3869 1 10 1M19 10H1M10 19C7.61305 19 5.32387 18.0518 3.63604 16.364C1.94821 14.6761 1 12.3869 1 10M10 19C11.657 19 13 14.97 13 10C13 5.03 11.657 1 10 1M10 19C8.343 19 7 14.97 7 10C7 5.03 8.343 1 10 1M10 1C7.61305 1 5.32387 1.94821 3.63604 3.63604C1.94821 5.32387 1 7.61305 1 10"
            style="stroke: var(--v-primary-base)"
            stroke-width="2"
            stroke-linecap="round"
            stroke-linejoin="round"
          />
        </svg>
        {{ $t('advica.callouts.languages') }}: {{ Languages }}
      </v-col>
      <v-col
        cols="12"
        sm="6"
        tag="li"
        class="highlights-item pa-0 pr-10 mb-3 mb-md-5 d-flex align-center"
        v-if="Coverage"
      >
        <!-- don't move svg to v-img because usage of a global variable for color -->
        <svg
          width="20"
          height="20"
          viewBox="0 0 20 20"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            d="M6 5V1V5ZM14 5V1V5ZM5 9H15H5ZM3 19H17C17.5304 19 18.0391 18.7893 18.4142 18.4142C18.7893 18.0391 19 17.5304 19 17V5C19 4.46957 18.7893 3.96086 18.4142 3.58579C18.0391 3.21071 17.5304 3 17 3H3C2.46957 3 1.96086 3.21071 1.58579 3.58579C1.21071 3.96086 1 4.46957 1 5V17C1 17.5304 1.21071 18.0391 1.58579 18.4142C1.96086 18.7893 2.46957 19 3 19Z"
            style="stroke: var(--v-primary-base)"
            stroke-width="2"
            stroke-linecap="round"
            stroke-linejoin="round"
          />
        </svg>
        {{ Coverage }} {{ $t('advica.callouts.coverage') }}
      </v-col>
    </div>

    <div class="description">
      <div
        data-cy="advica-details-overview-description"
        v-if="Boolean(description)"
      >
        {{ description }}
      </div>
      <v-skeleton-loader
        class="py-3"
        v-if="isLoading"
        max-width="400"
        type="text"
      />
    </div>
  </div>
</template>

<script>
import ADSectionTitle from './ADSectionTitle';

export default {
  name: 'ADHighlights',
  components: { ADSectionTitle },
  props: {
    Support: {
      type: String,
      default: '',
    },
    Coverage: {
      type: String,
      default: '',
    },
    Languages: {
      type: String,
      default: '',
    },
    description: {
      type: String,
      default: '',
    },
    isLoading: {
      type: Boolean,
      default: true,
    },
  },
};
</script>

<style scoped lang="scss">
.highlights {
  &-list {
    margin-top: 20px;
    list-style: none;

    @media (max-width: map-get($grid-breakpoints, sm)) {
      margin-top: 0;
    }
  }

  &-item {
    @include font-size(16, 24, 600);
    color: var(--v-grey8-base);

    svg {
      margin-right: 10px;
    }
  }
}
</style>
