var render = function render(){var _vm=this,_c=_vm._self._c;return _c('v-container',{staticClass:"advica-details-section pa-0 px-5 px-md-0"},[_c('v-row',{attrs:{"no-gutters":""}},[_c('v-col',{staticClass:"mb-4 mb-md-7",attrs:{"cols":"12"}},[(_vm.hasModel)?[_c('h1',{staticClass:"mt-8 mt-md-9 advica-details-title",attrs:{"data-cy":"product-details-title"}},[_vm._v(" "+_vm._s(_vm.title)+" ")])]:[_c('v-skeleton-loader',{attrs:{"max-width":"550","type":"text"}})]],2),_c('v-col',{staticClass:"page-navigation-sticky mb-8 mb-md-10",class:{
        'push-top': _vm.hasContextFlights || _vm.$vuetify.breakpoint.lgAndUp,
      },attrs:{"cols":"12"}},[_c('CategoryMenuBar',{attrs:{"menuItems":_vm.menuNames,"activeTabId":_vm.activeTabId},on:{"click":_vm.scrollToMenu}})],1)],1),(_vm.hasModel)?[_c('v-row',{staticClass:"wrapper",attrs:{"no-gutters":""}},[_c('v-col',{staticClass:"pr-md-12",attrs:{"col":"12","md":"8"}},[(_vm.highlights)?[_c('ADHighlights',{directives:[{name:"intersect",rawName:"v-intersect",value:({
              handler: _vm.onIntersect,
              options: {
                threshold: _vm.intersectThreshold,
                rootMargin: _vm.intersectRootMargin,
              },
            }),expression:"{\n              handler: onIntersect,\n              options: {\n                threshold: intersectThreshold,\n                rootMargin: intersectRootMargin,\n              },\n            }"}],ref:"highlights-id",attrs:{"description":_vm.description,"isLoading":_vm.isLoading}})]:_vm._e(),(_vm.howItWorks || _vm.benefits)?[_c('ADOverview',{directives:[{name:"intersect",rawName:"v-intersect",value:({
              handler: _vm.onIntersect,
              options: {
                threshold: _vm.intersectThreshold,
                rootMargin: _vm.intersectRootMargin,
              },
            }),expression:"{\n              handler: onIntersect,\n              options: {\n                threshold: intersectThreshold,\n                rootMargin: intersectRootMargin,\n              },\n            }"}],ref:"overview-id",attrs:{"isLoading":_vm.isLoading,"howItWorks":_vm.howItWorks,"benefits":_vm.benefits}})]:_vm._e(),(_vm.faqs)?[_c('ADMoreInformation',{directives:[{name:"intersect",rawName:"v-intersect",value:({
              handler: _vm.onIntersect,
              options: {
                threshold: _vm.intersectThreshold,
                rootMargin: _vm.intersectRootMargin,
              },
            }),expression:"{\n              handler: onIntersect,\n              options: {\n                threshold: intersectThreshold,\n                rootMargin: intersectRootMargin,\n              },\n            }"}],ref:"moreInformation-id",attrs:{"faqs":_vm.faqs,"isLoading":_vm.isLoading}})]:_vm._e(),(_vm.cancellation || _vm.securityAndPrivacy)?[_c('ADTermsConditions',{directives:[{name:"intersect",rawName:"v-intersect",value:({
              handler: _vm.onIntersect,
              options: {
                threshold: _vm.intersectThreshold,
                rootMargin: _vm.intersectRootMargin,
              },
            }),expression:"{\n              handler: onIntersect,\n              options: {\n                threshold: intersectThreshold,\n                rootMargin: intersectRootMargin,\n              },\n            }"}],ref:"cancellationPolicy-id",attrs:{"securityAndPrivacy":_vm.securityAndPrivacy,"cancellation":_vm.cancellation}})]:_vm._e()],2),(_vm.$vuetify.breakpoint.mdAndUp)?[_c('v-col',{staticClass:"pl-4",attrs:{"col":"12","md":"4"}},[_c('AdvicaCtaCardContainer',{staticClass:"sticky",attrs:{"model":_vm.model,"isLoading":_vm.isLoading}})],1)]:_vm._e()],2)]:_vm._e()],2)
}
var staticRenderFns = []

export { render, staticRenderFns }