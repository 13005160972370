<template>
  <div class="options-card_wrapper">
    <template v-if="$vuetify.breakpoint.smAndDown">
      <div class="options-card_button" @click="show = true">
        <v-container class="pa-0 options-cta-header">
          <v-row no-gutters>
            <v-col cols="6">
              <v-row class="mx-0">
                <v-col cols="12" class="pa-0">
                  <span>
                    {{
                      priceStartAt.value | currency(priceStartAt.baseCurrency)
                    }}
                    {{ priceStartAt.baseCurrency }}
                  </span>
                </v-col>
                <v-col cols="12" class="pa-0">
                  <v-skeleton-loader
                    max-width="550"
                    type="text"
                    :loading="isLoading"
                    class="per-person"
                  >
                    <span>
                      {{
                        $t('advica.from_price_per', {
                          type: productType ? productType : '',
                        })
                      }}
                    </span>
                  </v-skeleton-loader>
                </v-col>
              </v-row>
            </v-col>
            <v-col cols="6" class="check-availability pl-6 d-flex justify-end">
              {{ $t('advica.check_availability') }}
            </v-col>
          </v-row>
        </v-container>
      </div>

      <OptionsAdvicaCtaCardModule
        v-model="show"
        :price="priceStartAt"
        :loading="isLoading"
        :product-type="productType"
        v-on:select-option-click="goToAvailability()"
        :enableCta="true"
        :buttonText="$t('advica.check_availability')"
      />
    </template>

    <template v-else>
      <OptionsAdvicaCtaCard
        :price="priceStartAt"
        :loading="isLoading"
        :product-type="productType"
        v-on:select-option-click="goToAvailability()"
        :enableCta="true"
        :buttonText="$t('advica.check_availability')"
      />
    </template>
  </div>
</template>

<script>
import OptionsAdvicaCtaCard from './OptionsCtaCard/OptionsAdvicaCtaCard';
import OptionsAdvicaCtaCardModule from './OptionsCtaCard/OptionsAdvicaCtaCardModule';
import GoogleTagManagerMixins from '@/core/mixins/GoogleTagManagerMixins';

export default {
  name: 'AdvicaCtaCardContainer',
  components: { OptionsAdvicaCtaCardModule, OptionsAdvicaCtaCard },
  mixins: [GoogleTagManagerMixins],
  props: {
    model: { type: Object, default: null },
    isLoading: { type: Boolean, default: false },
  },
  data() {
    return {
      productType: this.$t('advica.product_type'),
      show: false,
    };
  },
  computed: {
    hasModel() {
      return this.model !== null && this.model !== undefined;
    },
    priceStartAt() {
      if (!this.hasModel) {
        return { value: 0, baseCurrency: this.defaultCurrency };
      } else {
        return this.model.priceStartingAt;
      }
    },
  },
  methods: {
    goToAvailability() {
      this.pushCheckoutAnalytics(this.productId, 1, 'advica');
      this.$router.push({ name: 'advica-forms' });
    },
  },
};
</script>

<style lang="scss" scoped>
.options-card {
  &_wrapper {
    width: 100%;
    background: #fff;
  }

  &_button {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-wrap: wrap;
    padding: 8px 20px;
    background: var(--v-primary-base);
    height: 58px;
    color: #fff;
    @include font-size(16, 24, 700);
  }
}

.per-person {
  @include font-size(12, 18, 700);
}

.check-availability {
  @include font-size(16, 24, 700);
}
</style>
