<script>
import AdvicaUserFormStoreMixins from '@/modules/product/advica/mixins/AdvicaUserFormStoreMixins';
import AdvicaDetailsMixins from '@/modules/product/advica/mixins/AdvicaDetailsMixins';
import FlightsMixins from '@/modules/flights/mixins/FlightsMixins';

export default {
  name: 'AdvicaCtaCardMixins',
  mixins: [AdvicaUserFormStoreMixins, AdvicaDetailsMixins, FlightsMixins],
  data() {
    return {
      selectedDate: null,
      steps: [
        {
          id: 1,
          name: this.$t('advica.date'),
          status: 'ACTIVE',
        },
        {
          id: 2,
          name: this.$t('advica.travelers'),
          status: 'PENDING',
        },
      ],
      currentStep: {
        id: 1,
        name: this.$t('advica.date'),
        status: 'ACTIVE',
      },
    };
  },
  computed: {
    disabledButton() {
      if (this.currentStep.id === 1 && this.selectedDateRange === null)
        return true;
      if (this.currentStep.id === 2 && this.getPasses.length === 0) return true;
      return this.loading || !this.enableCta || !this.USDestinationConfirmed;
    },
    startingPrice() {
      return {
        value: this.getStartingPrice,
        currency: this.getBaseCurrency,
      };
    },
  },
  methods: {
    selectOptionClick() {
      this.$emit('select-option-click');
    },
    goTo(step) {
      this.currentStep = this.steps[step];
      this.steps[0].status = 'DONE';
      this.steps[1].status = step >= 1 ? 'DONE' : 'PENDING';
    },
    buttonAction() {
      switch (this.currentStep.id) {
        case 1:
          this.goTo(1);
          break;
        case 2:
          this.selectOptionClick();
      }
    },
    onConfirmationChange(confirmed) {
      this.setUSConfirmation(confirmed);
    },
    onPassesChange(passes) {
      this.setPasses(passes);
    },
    onDateRangeChange(dateRange) {
      this.setDateRange(dateRange);
    },
    onClearDateRange() {
      this.removeDateRange();
    },
  },
  watch: {
    selectedContextFlight: {
      immediate: true, // this will make it gets called on the first initilization which is equivalent to making the call in the mounted hook
      handler() {
        this.goTo(0);
      },
    },
  },
};
</script>

<style scoped></style>
