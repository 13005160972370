<template>
  <div class="moreInformation">
    <ADSectionTitle
      data-cy="advica-details-more-info"
      :title="$t('advica.menu.more_information')"
      class="mb-5"
      :class="$vuetify.breakpoint.smAndDown ? 'size24-weight700' : ''"
    />
    <div v-for="(faq, i) in faqs" :key="i">
      <h3 class="size18-weight700 mb-2">{{ faq.label }}</h3>
      <div class="size16-weight400">
        {{ faq.value }}
      </div>
      <v-divider v-if="!!faqs[i + 1]" class="mt-4 mb-5 mb-md-4" />
    </div>
    <v-skeleton-loader
      class="py-3"
      v-if="isLoading"
      max-width="400"
      type="text"
    />
  </div>
</template>

<script>
import ADSectionTitle from './ADSectionTitle';

export default {
  name: 'ADMoreInformation',
  components: { ADSectionTitle },
  props: {
    faqs: {
      type: Array,
      default: () => [],
    },
    isLoading: {
      type: Boolean,
      default: true,
    },
  },
};
</script>
