<template>
  <v-container class="advica-details-section pa-0 px-5 px-md-0">
    <v-row no-gutters>
      <v-col cols="12" class="mb-4 mb-md-7">
        <template v-if="hasModel">
          <h1
            data-cy="product-details-title"
            class="mt-8 mt-md-9 advica-details-title"
          >
            {{ title }}
          </h1>
        </template>

        <template v-else>
          <v-skeleton-loader max-width="550" type="text" />
        </template>
      </v-col>
      <v-col
        cols="12"
        class="page-navigation-sticky mb-8 mb-md-10"
        :class="{
          'push-top': hasContextFlights || $vuetify.breakpoint.lgAndUp,
        }"
      >
        <CategoryMenuBar
          @click="scrollToMenu"
          :menuItems="menuNames"
          :activeTabId="activeTabId"
        />
      </v-col>
    </v-row>

    <template v-if="hasModel">
      <v-row no-gutters class="wrapper">
        <v-col col="12" md="8" class="pr-md-12">
          <template v-if="highlights">
            <ADHighlights
              v-intersect="{
                handler: onIntersect,
                options: {
                  threshold: intersectThreshold,
                  rootMargin: intersectRootMargin,
                },
              }"
              ref="highlights-id"
              :description="description"
              :isLoading="isLoading"
            />
          </template>

          <template v-if="howItWorks || benefits">
            <ADOverview
              v-intersect="{
                handler: onIntersect,
                options: {
                  threshold: intersectThreshold,
                  rootMargin: intersectRootMargin,
                },
              }"
              :isLoading="isLoading"
              :howItWorks="howItWorks"
              :benefits="benefits"
              ref="overview-id"
            />
          </template>

          <template v-if="faqs">
            <ADMoreInformation
              v-intersect="{
                handler: onIntersect,
                options: {
                  threshold: intersectThreshold,
                  rootMargin: intersectRootMargin,
                },
              }"
              :faqs="faqs"
              :isLoading="isLoading"
              ref="moreInformation-id"
            />
          </template>

          <template v-if="cancellation || securityAndPrivacy">
            <ADTermsConditions
              v-intersect="{
                handler: onIntersect,
                options: {
                  threshold: intersectThreshold,
                  rootMargin: intersectRootMargin,
                },
              }"
              :securityAndPrivacy="securityAndPrivacy"
              :cancellation="cancellation"
              ref="cancellationPolicy-id"
            />
          </template>
        </v-col>

        <template v-if="$vuetify.breakpoint.mdAndUp">
          <v-col col="12" md="4" class="pl-4">
            <AdvicaCtaCardContainer
              class="sticky"
              :model="model"
              :isLoading="isLoading"
            />
          </v-col>
        </template>
      </v-row>
    </template>
  </v-container>
</template>

<script>
import ADHighlights from './AdvicaDetailsSection/ADHighlights';
import ADOverview from './AdvicaDetailsSection/ADOverview';
import ADTermsConditions from './AdvicaDetailsSection/ADTermsConditions';
import ADMoreInformation from './AdvicaDetailsSection/ADMoreInformation';
import GoogleTagManagerMixins from '@/core/mixins/GoogleTagManagerMixins';
import FlightsMixins from '@/modules/flights/mixins/FlightsMixins';
import AdvicaCtaCardContainer from '@/modules/product/advica/features/Details/AdvicaCtaCardContainer';
import CategoryMenuBar from '@/core/components/CategoryMenuBar';

export default {
  name: 'advica-details-section',
  components: {
    ADHighlights,
    ADTermsConditions,
    AdvicaCtaCardContainer,
    CategoryMenuBar,
    ADOverview,
    ADMoreInformation,
  },
  mixins: [GoogleTagManagerMixins, FlightsMixins],
  props: { model: Object, isLoading: Boolean, hasError: Boolean },

  data() {
    return {
      highlightsActive: false,
      overviewActive: false,
      moreInfoActive: false,
      privacyActive: false,
      activeBlocks: [],
      intersectThreshold: [0, 0.2],
      intersectRootMargin: '-235px 0px 0px 0px',
    };
  },

  computed: {
    activeTabId() {
      let sortedTabs = this.activeBlocks
        .map((item) => ({
          id: item,
          order: this.menuNames.findIndex((menuItem) => menuItem.id === item),
        }))
        .sort((a, b) => a.order - b.order);
      return sortedTabs.length > 0 ? sortedTabs[0] : null;
    },

    menuNames() {
      let array = [
        {
          id: 'highlights-id',
          title: this.$t('advica.menu.highlights'),
          isShow: !!this.highlights,
          isActive: this.highlightsActive,
        },
        {
          id: 'overview-id',
          title: this.$t('advica.menu.overview'),
          isShow: !!this.hasModel,
          isActive: this.overviewActive,
        },
        {
          id: 'moreInformation-id',
          title: this.$t('advica.menu.more_information'),
          isShow: !!this.faqs,
          isActive: this.moreInfoActive,
        },
        {
          id: 'cancellationPolicy-id',
          title: this.$t('advica.menu.terms_conditions'),
          isShow: !!this.cancellation || !!this.securityAndPrivacy,
          isActive: this.privacyActive,
        },
      ];

      return array.filter((el) => el.isShow == true);
    },

    hasModel() {
      return this.model !== null && this.model !== undefined;
    },

    title() {
      return this.hasModel ? this.model.title : 'Lorem ipsum dolor sit amet';
    },

    highlights() {
      return this.hasModel ? this.model.highlights : null;
    },

    priceStartAt() {
      if (!this.hasModel) {
        return { value: 0, baseCurrency: this.defaultCurrency };
      } else {
        return this.model.priceStartingAt;
      }
    },

    description() {
      return this.hasModel ? this.model.description : null;
    },

    howItWorks() {
      return this.getInformationValue(
        this.$t('advica.details_sections.how_it_works')
      );
    },

    benefits() {
      return this.getInformationValue(
        this.$t('advica.details_sections.benefits')
      );
    },

    cancellation() {
      return this.getInformationValue(
        this.$t('advica.details_sections.cancellation_policy')
      );
    },

    securityAndPrivacy() {
      return this.getInformationValue(
        this.$t('advica.details_sections.security_privacy')
      );
    },

    faqs() {
      if (!this.hasModel) return null;
      const questions = [
        this.$t('advica.details_sections.how_it_works'),
        this.$t('advica.details_sections.benefits'),
        this.$t('advica.details_sections.cancellation_policy'),
        this.$t('advica.details_sections.security_privacy'),
      ];
      const { information } = this.model;
      return information.filter((info) => {
        return questions.findIndex((i) => i === info.label) < 0;
      });
    },
  },

  methods: {
    onIntersect(entries) {
      entries[0].target.classList.forEach((item) => {
        let blockName = this.getBlockName(item);
        if (blockName) {
          this.intersectController(entries[0].isIntersecting, blockName);
        }
      });
    },

    getBlockName(name) {
      const blockNamesToId = {
        highlights: 'highlights-id',
        overview: 'overview-id',
        moreInformation: 'moreInformation-id',
        cancellationPolicy: 'cancellationPolicy-id',
      };
      return blockNamesToId[name];
    },

    intersectController(isIntersecting, blockName) {
      if (isIntersecting) {
        this.setActiveBlock(blockName);
      } else {
        this.removeActiveBlock(blockName);
      }
    },

    setActiveBlock(block) {
      let activeBlocks = [...this.activeBlocks, block];
      this.activeBlocks = Object.assign([], [...new Set(activeBlocks)]);
    },

    removeActiveBlock(block) {
      this.activeBlocks.splice(this.activeBlocks.indexOf(block), 1);
    },

    getInformationValue(label, { returnLabel } = { returnLabel: false }) {
      if (!this.hasModel) return null;
      const { information } = this.model;
      if (information) {
        const info = information.find((info) => info.label === label);
        if (info) {
          return returnLabel ? info : info.value;
        }
      }
      return null;
    },

    goToAvailability() {
      this.pushCheckoutAnalytics(this.productId, 1, 'advica');
      this.$router.push({ name: 'advica-forms' });
    },

    scrollToMenu(refName) {
      let element = this.$refs[refName].$el;
      if (!!element) {
        const y =
          element.getBoundingClientRect().top +
          window.pageYOffset -
          (this.$vuetify.breakpoint.mdAndDown ? 150 : 230);
        window.scrollTo({ top: y, behavior: 'smooth' });
      }
    },
  },
};
</script>

<style lang="scss" scoped>
.advica-details-title {
  @include font-size(24, 29, 300);

  @media (min-width: map-get($grid-breakpoints, md)) {
    @include font-size(36, 150, 300);
  }
}

.advica-details-section {
  position: relative;
}

.sticky {
  position: -webkit-sticky;
  position: sticky;
  top: 260px;
}

.wrapper {
  display: flex;
}

::v-deep .moreInformation,
::v-deep .description,
::v-deep .information {
  letter-spacing: 0;
  margin-bottom: 60px;

  @media (min-width: map-get($grid-breakpoints, sm)) {
    margin-bottom: 80px;
  }
}

::v-deep .overview {
  letter-spacing: 0;
  margin-bottom: 68px;

  @media (min-width: map-get($grid-breakpoints, sm)) {
    margin-bottom: 88px;
  }
}

::v-deep .instructions-section p {
  background: #faf9f7;
  @include font-size(18, 25);
  color: #27241d;
  border: 2px dashed #e8e6e1;
  box-sizing: border-box;
  border-radius: 4px;
  padding: 16px;
}

::v-deep .v-expansion-panel {
  box-shadow: none;

  &:before {
    box-shadow: none !important;
  }
}

.page-navigation-sticky {
  position: -webkit-sticky;
  position: sticky;
  top: 80px;
  background: white;
  z-index: 4;

  &.push-top {
    top: 76px;

    @media (min-width: map-get($grid-breakpoints, sm)) {
      top: 164px;
    }

    @media (min-width: map-get($grid-breakpoints, md)) {
      top: 176px;
    }
  }
}
</style>
