<template>
  <PassTypes
    :title="$t('advica.title')"
    :passes="passes"
    @passChange="passesSelected($event)"
    typePass="day"
  />
</template>

<script>
import PassTypes from '@/modules/product/features/PassTypes';

export default {
  name: 'AdvicaProductPasses',
  components: { PassTypes },
  props: {
    selectedPasses: Array,
    price: {
      type: Object,
      default: () => [],
    },
  },
  data() {
    let adultPass = 0;
    let childPass = 0;
    let priceAmmount = 0;
    let priceCurrency = '';
    if (this.selectedPasses) {
      adultPass = this.selectedPasses.find((pass) => pass.id === 'adult');
      childPass = this.selectedPasses.find((pass) => pass.id === 'child');
      priceAmmount = this.price.value;
      priceCurrency = this.price.currency;
    }
    return {
      passes: [
        {
          id: 'adult',
          name: this.$t('advica.passes.adult'),
          price: {
            value: priceAmmount || 0.0,
            baseCurrency: priceCurrency || '',
          },
          value: adultPass ? adultPass.value : 0,
          max: 20,
          min: 0,
          description: this.$t('advica.passes.adult_description'),
        },
        {
          id: 'child',
          name: this.$t('advica.passes.child'),
          price: {
            value: priceAmmount || 0.0,
            baseCurrency: priceCurrency || '',
          },
          value: childPass ? childPass.value : 0,
          max: adultPass ? 20 : 0,
          min: 0,
          description: this.$t('advica.passes.child_description'),
        },
      ],
    };
  },
  methods: {
    updatePassValue(pass) {
      const passIndex = this.passes.findIndex((_pass) => _pass.id === pass.id);
      if (passIndex > -1) this.passes[passIndex].value = pass.value;
    },
    adjustChildPass(pass) {
      if (pass.value > 0) {
        this.passes[1].max = 20;
      } else {
        this.passes[1].max = 0;
        this.passes[1].value = 0;
      }
    },
    passesSelected(pass) {
      this.updatePassValue(pass);
      if (pass.id === 'adult') {
        this.adjustChildPass(pass);
      }
      this.$emit(
        'change',
        this.passes
          .filter((_pass) => _pass.value > 0)
          .map((_pass, i) => ({
            id: _pass.id,
            value: _pass.value,
            totalPrice: {
              value: this.price.value * _pass.value,
              currency: this.price.currency,
            },
          }))
      );
    },
  },
};
</script>
