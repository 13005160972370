<template>
  <AdvicaDetails
    :model="details"
    :isLoading="this.isLoading"
    :error="this.error"
  />
</template>

<script>
import AdvicaDetails from '@/modules/product/advica/features/Details/AdvicaDetails';
import AdvicaDetailsMixins from '@/modules/product/advica/mixins/AdvicaDetailsMixins';
import GoogleTagManagerMixins from '@/core/mixins/GoogleTagManagerMixins';

export default {
  name: 'AdvicaDetailsPage',
  components: { AdvicaDetails },
  mixins: [AdvicaDetailsMixins, GoogleTagManagerMixins],
  created() {
    if (this.hasDetails) {
      this.fireDetailsViewEvent();
    }
  },
  computed: {
    details() {
      return this.detailsModel;
    },
  },
  methods: {
    fireDetailsViewEvent() {
      this.pushProductDetailsViewAnalytics(
        this.productId,
        'advica',
        'list',
        'Advica - Product Details'
      );
    },
  },
};
</script>
