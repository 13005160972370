<template>
  <v-dialog
    v-model="show"
    fullscreen
    hide-overlay
    transition="dialog-bottom-transition"
  >
    <v-sheet class="options-cta-card d-flex flex-column" color="white">
      <v-container
        class="px-2 pt-3 pb-1 d-flex justify-space-between align-center options-cta-mobile-header"
      >
        <v-btn icon small @click="prevStep">
          <v-icon>
            mdi-chevron-left
          </v-icon>
        </v-btn>
        <span class="size16-weight700">
          {{ $t('advica.check_availability') }}
        </span>
        <v-btn icon small @click="closeDialog">
          <v-icon>
            mdi-close
          </v-icon>
        </v-btn>
      </v-container>
      <v-container class="pa-0 options-cta-header">
        <v-row class="pa-5 mx-0">
          <v-col cols="12" class="pa-0">
            <span class="size24-weight700">
              {{ price.value | currency(price.baseCurrency) }}
              {{ price.baseCurrency }}
            </span>
          </v-col>
          <v-col cols="12" class="pa-0">
            <v-skeleton-loader
              max-width="550"
              type="text"
              :loading="loading"
              class="size14-weight700 per-person"
            >
              <span>{{
                $t('advica.from_price_per', { type: productType })
              }}</span>
            </v-skeleton-loader>
          </v-col>
        </v-row>
      </v-container>
      <v-divider />
      <v-container class="px-5 pt-5">
        <ProgressIndicatorDetailsWrapper :steps="steps" />
      </v-container>
      <v-container v-if="currentStep.id === 1" class="pl-5 pt-2 pr-9 pb-0 mb-4">
        <AdvicaDateRangePicker
          @change="onDateRangeChange"
          :selectedDateRange="selectedDateRange"
        />
      </v-container>
      <v-container v-if="currentStep.id === 2" class="pa-0 pt-1 mb-1">
        <AdvicaProductPasses
          :price="startingPrice"
          :selectedPasses="selectedPasses"
          @change="onPassesChange"
        />
      </v-container>
      <v-container v-if="currentStep.id == 1" class="pa-0 pr-4">
        <AdvicaUSConfirmation
          @change="onConfirmationChange"
          :confirmed="USDestinationConfirmed"
        />
      </v-container>
      <v-divider />
      <v-container class="pa-5">
        <OptionsAdvicaSteps
          :currentStep="currentStep"
          @goToDateStep="goTo(0)"
        />
      </v-container>
      <v-spacer />
      <v-container class="pa-0">
        <v-row no-gutters>
          <v-col>
            <v-btn
              tile
              :disabled="disabledButton"
              color="primary"
              @click="buttonAction"
              block
              data-cy="select-booking-option"
              x-large
            >
              <template
                class="cta-card-button size20-weight700 mt-3"
                v-if="currentStep.id === 2"
              >
                {{ $t('advica.get_plan') }} ({{ priceText }})
              </template>
              <template class="cta-card-button size20-weight700 mt-3" v-else>
                {{ buttonText }}
              </template>
            </v-btn>
          </v-col>
        </v-row>
      </v-container>
    </v-sheet>
  </v-dialog>
</template>

<script>
import ProgressIndicatorDetailsWrapper from '@/modules/product/features/ProgressIndicator/ProgressIndicatorDetailsWrapper.vue';
import AdvicaProductPasses from './components/AdvicaProductPasses';
import AdvicaUSConfirmation from './components/AdvicaUSConfirmation';
import AdvicaDateRangePicker from './components/AdvicaDateRangePicker';
import OptionsAdvicaSteps from './components/OptionsAdvicaSteps';
import AdvicaCtaCardMixins from '@/modules/product/advica/mixins/AdvicaCtaCardMixins';
import AdvicaUserFormStoreMixins from '@/modules/product/advica/mixins/AdvicaUserFormStoreMixins';

export default {
  name: 'options-advica-cta-card',
  components: {
    ProgressIndicatorDetailsWrapper,
    AdvicaProductPasses,
    AdvicaUSConfirmation,
    AdvicaDateRangePicker,
    OptionsAdvicaSteps,
  },
  mixins: [AdvicaCtaCardMixins, AdvicaUserFormStoreMixins],
  props: {
    value: Boolean,
    loading: {
      default: true,
      type: Boolean,
    },
    price: {
      default: () => ({ value: 200, baseCurrency: 'USD' }),
      type: Object,
    },
    productType: {
      default: '',
      type: String,
    },
    buttonText: {
      default: function() {
        return this.$t('advica.check_availability');
      },
      type: String,
    },
    enableCta: {
      default: true,
      type: Boolean,
    },
  },
  computed: {
    show: {
      get() {
        return this.value;
      },
      set(value) {
        this.$emit('input', value);
      },
    },
  },
  methods: {
    closeDialog() {
      this.show = false;
    },
    prevStep() {
      if (this.currentStep.id === 1) {
        this.show = false;
      } else if (this.currentStep.id === 2) {
        this.goTo(0);
      }
    },
  },
};
</script>

<style lang="scss" scoped>
.options-cta-header {
  background: var(--v-grey1-base);
}

.options-cta-card {
  width: 100%;
  height: 100%;
  background: transparent;
  overflow: scroll;
  border-color: #e8e6e1;
  box-sizing: border-box;
  box-shadow: 0px 4px 6px rgba(0, 0, 0, 0.16);
  .v-divider {
    border-width: 1px;
  }
  .primary {
    fill: var(--v-primary-base);
  }
  .per-person {
    color: var(--v-grey7-base);
  }
}

.cta-card-button {
  letter-spacing: 0;
}
</style>
