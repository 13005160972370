<template>
  <v-sheet class="options-cta-card" data-cy="options-cta-card">
    <v-container class="pa-0 options-cta-header">
      <v-row class="pa-5 pb-4 mx-0">
        <v-col cols="12" class="pa-0">
          <span class="size24-weight700">
            {{ price.value | currency(price.baseCurrency) }}
            {{ price.baseCurrency }}
          </span>
        </v-col>
        <v-col cols="12" class="pa-0">
          <v-skeleton-loader
            max-width="550"
            type="text"
            :loading="loading"
            class="size14-weight700 per-person"
          >
            <span>{{
              $t('advica.from_price_per', { type: productType })
            }}</span>
          </v-skeleton-loader>
        </v-col>
      </v-row>
    </v-container>
    <v-divider />
    <v-container class="px-5 pt-4 pb-1">
      <ProgressIndicatorDetailsWrapper :steps="steps" />
    </v-container>
    <v-container v-if="currentStep.id === 1" class="px-5 pb-3">
      <AdvicaDateRangePicker
        @change="onDateRangeChange"
        @clear="onClearDateRange"
        :selectedDateRange="selectedDateRange"
      />
    </v-container>
    <v-container v-if="currentStep.id === 2" class="pa-0">
      <AdvicaProductPasses
        :price="startingPrice"
        :selectedPasses="selectedPasses"
        @change="onPassesChange"
      />
    </v-container>
    <v-container v-if="currentStep.id == 1" class="pa-0">
      <AdvicaUSConfirmation
        @change="onConfirmationChange"
        :confirmed="USDestinationConfirmed"
      />
    </v-container>
    <v-divider />
    <v-container class="px-5 pb-3 pt-6">
      <OptionsAdvicaSteps :currentStep="currentStep" @goToDateStep="goTo(0)" />
    </v-container>
    <v-spacer />
    <v-btn
      tile
      :disabled="disabledButton"
      color="primary"
      @click="buttonAction"
      block
      class="cta-card-button size20-weight700 mt-3"
      data-cy="select-booking-option"
      x-large
    >
      <template v-if="currentStep.id === 2">
        {{ priceText }} - {{ $t('advica.book_now') }}
      </template>
      <template v-else>
        {{ buttonText }}
      </template>
    </v-btn>
  </v-sheet>
</template>
<script>
import ProgressIndicatorDetailsWrapper from '@/modules/product/features/ProgressIndicator/ProgressIndicatorDetailsWrapper.vue';
import AdvicaProductPasses from './components/AdvicaProductPasses';
import AdvicaUSConfirmation from './components/AdvicaUSConfirmation';
import AdvicaDateRangePicker from './components/AdvicaDateRangePicker';
import OptionsAdvicaSteps from './components/OptionsAdvicaSteps';
import AdvicaCtaCardMixins from '@/modules/product/advica/mixins/AdvicaCtaCardMixins';
import AdvicaUserFormStoreMixins from '@/modules/product/advica/mixins/AdvicaUserFormStoreMixins';

export default {
  name: 'options-advica-cta-card',
  components: {
    ProgressIndicatorDetailsWrapper,
    AdvicaProductPasses,
    AdvicaUSConfirmation,
    AdvicaDateRangePicker,
    OptionsAdvicaSteps,
  },
  mixins: [AdvicaCtaCardMixins, AdvicaUserFormStoreMixins],
  props: {
    loading: {
      default: true,
      type: Boolean,
    },
    price: {
      default: () => ({ value: 200, baseCurrency: 'USD' }),
      type: Object,
    },
    productType: {
      default: function() {
        return this.$t('advica.product_type_person');
      },
      type: String,
    },
    buttonText: {
      default: function() {
        return this.$t('advica.check_availability');
      },
      type: String,
    },
    enableCta: {
      default: true,
      type: Boolean,
    },
  },
};
</script>

<style lang="scss" scoped>
.options-cta-header {
  background: var(--v-grey1-base);
}

.options-cta-card {
  width: 100%;
  background: transparent;
  border-radius: 8px;
  border-color: #e8e6e1;
  box-sizing: border-box;
  box-shadow: 0px 4px 6px rgba(0, 0, 0, 0.16);
  .v-divider {
    border-width: 1px;
  }
  .primary {
    fill: var(--v-primary-base);
  }
  .per-person {
    color: var(--v-grey7-base);
  }
}

.cta-card-button {
  border-radius: 0 0 8px 8px;
  letter-spacing: 0;
}
</style>
