<template>
  <v-container fluid class="pa-0">
    <v-row no-gutters>
      <v-col cols="12">
        <v-img
          alt="image"
          :src="imageUrl"
          max-height="360"
          class="mb-1 mb-md-0"
        />
      </v-col>
      <v-col cols="12" class="advica-details">
        <AdvicaDetailsSection
          :model="model"
          :isLoading="showLoading"
          :hasError="hasError"
        />
        <template v-if="$vuetify.breakpoint.smAndDown">
          <div class="wrapper-cta">
            <AdvicaCtaCardContainer :model="model" :isLoading="showLoading" />
          </div>
        </template>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import AdvicaDetailsSection from '@/modules/product/advica/features/Details/AdvicaDetailsSection';
import AdvicaCtaCardContainer from '@/modules/product/advica/features/Details/AdvicaCtaCardContainer';

export default {
  name: 'advica-details',
  components: {
    AdvicaDetailsSection,
    AdvicaCtaCardContainer,
  },
  props: {
    model: Object,
    isLoading: Boolean,
    error: Object,
  },

  computed: {
    hasModel() {
      return this.model !== null && this.model !== undefined;
    },

    hasError() {
      return this.error !== null && this.error !== undefined && !this.hasModel;
    },

    showLoading() {
      return this.isLoading || this.error !== null;
    },

    imageUrl() {
      if (this.hasModel && this.model.imageUrls) {
        return this.model.imageUrls[0];
      }
      return require('@/assets/placeholder-image.jpg');
    },
  },
};
</script>

<style lang="scss" scoped>
.advica-details {
  display: flex;
}

.wrapper-cta {
  max-width: 100%;
  position: fixed;
  bottom: -1px;
  width: 100%;
  background: #fff;
  z-index: 999;
}
</style>
