<template>
  <div class="cancellationPolicy">
    <ADSectionTitle
      data-cy="advica-details-more-info"
      :title="$t('advica.menu.terms_conditions')"
      class="mb-5"
      :class="$vuetify.breakpoint.smAndDown ? 'size24-weight700' : ''"
    />
    <div class="mb-5">
      <h3>{{ $t('advica.menu.cancellation') }}</h3>
      <div class="mb-2">
        <div
          class="mb-5"
          data-cy="advica-details-policy-cancellation"
          v-if="cancellation"
        >
          {{ cancellation }}
        </div>
        <v-skeleton-loader
          class="py-3"
          v-if="isLoading"
          max-width="400"
          type="text"
        />
        <v-divider class="my-2" />
      </div>
    </div>
    <div>
      <h3>{{ $t('advica.details_sections.security_privacy') }}</h3>
      <div>
        <div
          class="mt-1 mb-6"
          data-cy="advica-details-policy-privacyAndSecurity"
          v-if="securityAndPrivacy"
        >
          {{ securityAndPrivacy }}
        </div>
        <v-skeleton-loader
          class="py-3"
          v-if="isLoading"
          max-width="400"
          type="text"
        />
      </div>
    </div>
  </div>
</template>

<script>
import ADSectionTitle from './ADSectionTitle';

export default {
  name: 'ADTermsConditions',
  components: { ADSectionTitle },
  props: {
    data: {
      type: String,
      default: '',
    },
    isLoading: {
      type: Boolean,
      default: false,
    },
    cancellation: {
      type: String,
      default: '',
    },
    securityAndPrivacy: {
      type: String,
      default: '',
    },
  },
};
</script>

<style scoped lang="scss">
.cancellationPolicy {
  letter-spacing: 0;
  margin-bottom: 60px;

  @media (min-width: map-get($grid-breakpoints, sm)) {
    margin-bottom: 300px;
  }
}
</style>
