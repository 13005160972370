<template>
  <v-form ref="confirmationForm" id="confirmation">
    <v-container class="pa-0">
      <v-row class="ma-0">
        <v-col cols="12" class="pa-6 pt-0">
          <v-checkbox
            class="mt-3 confirmation-checkbox"
            v-model="confirmedModel"
            hide-details
            :rules="rules"
            dense
            :label="$t('advica.message_confirm_plan')"
          />
        </v-col>
      </v-row>
    </v-container>
  </v-form>
</template>

<script>
export default {
  name: 'AdvicaUSConfirmation',
  props: {
    confirmed: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      confirmedModel: this.confirmed,
      rules: [(v) => !!v || this.$t('required')],
    };
  },
  watch: {
    confirmedModel(confirmed) {
      this.$emit('change', confirmed);
    },
  },
};
</script>

<style scoped lang="scss">
::v-deep .confirmation-checkbox {
  .v-label {
    color: #000;
    font-size: 14px;
    font-weight: 700;
  }
}
</style>
