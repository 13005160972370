<template>
  <div class="options-result">
    <div class="size14-weight700 d-flex align-start">
      <div class="d-flex justify-center options-result-icon">
        <svg
          width="18"
          height="17"
          viewBox="0 0 18 17"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            d="
              M5.66667 4.33333V1V4.33333ZM12.3333 4.33333V1V4.33333ZM4.83333 7.66667H13.1667H4.83333ZM3.16667
              16H14.8333C15.2754 16 15.6993 15.8244 16.0118 15.5118C16.3244 15.1993 16.5 14.7754 16.5 14.3333V4.33333C16.5
              3.89131 16.3244 3.46738 16.0118 3.15482C15.6993 2.84226 15.2754 2.66667 14.8333 2.66667H3.16667C2.72464
              2.66667 2.30072 2.84226 1.98816 3.15482C1.67559 3.46738 1.5 3.89131 1.5 4.33333V14.3333C1.5 14.7754
              1.67559 15.1993 1.98816 15.5118C2.30072 15.8244 2.72464 16 3.16667 16Z
            "
            :stroke="
              currentStep.id != 1
                ? $vuetify.theme.themes.light.primary.base
                : $vuetify.theme.themes.light.grey9
            "
            stroke-width="2"
            stroke-linecap="round"
            stroke-linejoin="round"
          />
        </svg>
      </div>
      <span class="ml-2">
        {{
          selectedDateRange
            ? formatDate(selectedDateRange)
            : $t('advica.no_date')
        }}

        <template v-if="currentStep.id != 1">
          <span>
            (<a @click="goToDateStep()"> {{ $t('change') }} </a>)
          </span>
        </template>
      </span>
    </div>

    <template v-if="currentStep.id == 2">
      <div class="size14-weight700 d-flex align-start">
        <div class="d-flex justify-center options-result-icon">
          <svg
            width="18"
            height="17"
            viewBox="0 0 18 17"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              d="
              M9 2.12833C9.44787 1.62059 10.0397 1.26126 10.6968 1.09816C11.354
              0.935066 12.0451 0.975936 12.6785 1.21533C13.3118 1.45473 13.8572
              1.88131 14.2421 2.4383C14.627 2.99529 14.8332 3.65629 14.8332
              4.33333C14.8332 5.01038 14.627 5.67138 14.2421 6.22837C13.8572
              6.78536 13.3118 7.21193 12.6785 7.45133C12.0451 7.69073 11.354
              7.7316 10.6968 7.56851C10.0397 7.40541 9.44787 7.04608 9 6.53833M11.5
              16H1.5V15.1667C1.5 13.8406 2.02678 12.5688 2.96447 11.6311C3.90215
              10.6935 5.17392 10.1667 6.5 10.1667C7.82608 10.1667 9.09785 10.6935
              10.0355 11.6311C10.9732 12.5688 11.5 13.8406 11.5 15.1667V16ZM11.5
              16H16.5V15.1667C16.5001 14.2889 16.2692 13.4266 15.8304 12.6664C15.3916
              11.9062 14.7604 11.2749 14.0003 10.836C13.2402 10.3971 12.3779 10.166
              11.5002 10.166C10.6224 10.1659 9.76015 10.397 9 10.8358M9.83333
              4.33333C9.83333 5.21739 9.48214 6.06524 8.85702 6.69036C8.2319 7.31548
              7.38405 7.66667 6.5 7.66667C5.61594 7.66667 4.7681 7.31548 4.14298
              6.69036C3.51786 6.06524 3.16667 5.21739 3.16667 4.33333C3.16667 3.44928
              3.51786 2.60143 4.14298 1.97631C4.7681 1.35119 5.61594 1 6.5 1C7.38405
              1 8.2319 1.35119 8.85702 1.97631C9.48214 2.60143 9.83333 3.44928 9.83333 4.33333Z
            "
              :stroke="
                currentStep.id != 2 || getPasses
                  ? $vuetify.theme.themes.light.primary.base
                  : $vuetify.theme.themes.light.grey9
              "
              stroke-width="2"
              stroke-linecap="round"
              stroke-linejoin="round"
            />
          </svg>
        </div>

        <template v-if="getPasses && getPasses.length > 0">
          <div class="px-2">
            <span
              class="options-result-travelers"
              v-for="(traveler, index) in travelersCount"
              :key="index"
            >
              {{ traveler.id }}: {{ traveler.value }}
            </span>
          </div>
        </template>

        <template v-else>
          <span class="ml-2">{{ $t('advica.no_data') }}</span>
        </template>
      </div>
    </template>
  </div>
</template>
<script>
import moment from 'moment';
import AdvicaUserFormStoreMixins from '@/modules/product/advica/mixins/AdvicaUserFormStoreMixins';

export default {
  name: 'OptionsAdvicaSteps',
  props: ['currentStep'],
  mixins: [AdvicaUserFormStoreMixins],

  methods: {
    formatDate(val) {
      return `${this.$t('advica.from')} ${this.getDate(
        val[0],
        'MD'
      )}, ${this.getDate(val[0], 'Y')}
       ${this.$t('advica.to')} ${this.getDate(val[1], 'MD')}, ${this.getDate(
        val[1],
        'Y'
      )}`;
    },

    getDate(val, type) {
      let date = moment(val);
      let result;

      switch (type) {
        case 'MD':
          result = date.format('MMM DD');
          break;
        case 'Y':
          result = date.format('YYYY');
      }
      return result;
    },

    goToDateStep() {
      this.$emit('goToDateStep');
    },
  },

  computed: {
    travelersCount() {
      let travelers = [];
      this.getPasses.forEach((item) => {
        let newItem = {
          id: `${item.id[0].toUpperCase()}${item.id.slice(1)}`,
          totalPrice: item.totalPrice,
          value: item.value,
        };

        travelers.push(newItem);
      });

      return travelers;
    },
  },
};
</script>

<style lang="scss" scoped>
::v-deep.options {
  &-result {
    & > div {
      margin-bottom: 10px;

      &:last-child {
        margin-bottom: 0;
      }
    }

    &-icon {
      padding-top: 2px;
    }

    &-travelers {
      position: relative;

      &::after {
        content: '/';
      }

      &:last-child {
        &::after {
          display: none;
        }
      }
    }
  }
}
</style>
