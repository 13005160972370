<template>
  <v-container class="pa-0 pt-3">
    <v-row no-gutters class="d-flex justify-start flex-wrap">
      <v-col class="col-auto pl-0 pl-5">
        <h1 class="size16-weight400">
          {{ $t('product.pass_one_adult_required') }}
        </h1>
      </v-col>
    </v-row>
    <v-row>
      <v-col>
        <v-card :loading="loading" flat>
          <template v-if="hasError">
            <v-card-title>{{ error }}</v-card-title>
          </template>

          <template v-if="!hasError">
            <v-container class="justify-center pa-0">
              <template v-for="(pass, index) in internalPasses">
                <quantity-field-widget
                  class="pa-5 pt-3 pb-4"
                  :key="pass.id"
                  :id="pass.id"
                  :title="pass.name"
                  :subTitle="pass.description"
                  :value="pass.value"
                  :max="pass.max"
                  :min="pass.min"
                  :price="getPrice(pass.price)"
                  :valueSupportText="getFormattedPrice(pass.price)"
                  @input="$emit('passChange', { id: pass.id, value: $event })"
                />

                <template v-if="index + 1 < passes.length">
                  <v-divider class="mx-5" :key="index" />
                </template>
              </template>
            </v-container>
          </template>
        </v-card>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import QuantityFieldWidget from '@/core/components/QuantityFieldWidget';

export default {
  name: 'PassTypes',
  components: {
    QuantityFieldWidget,
  },

  props: {
    title: {
      type: String,
      default: "Who's Coming?",
    },
    passes: {
      type: Array,
      default: () => [],
    },
    isLoading: Boolean,
    error: String,
    typePass: String,
  },

  data() {
    return {
      internalPasses: this.passes,
    };
  },

  computed: {
    loading() {
      return this.isLoading ? 'accent' : false;
    },

    hasModel() {
      return (
        this.passes &&
        this.passes?.value !== null &&
        this.passes?.value !== undefined
      );
    },

    hasError() {
      return this.error !== null && this.error !== undefined && !this.hasModel;
    },
  },

  methods: {
    getFormattedPrice(price) {
      let formattedPrice = 0;
      if (price) {
        formattedPrice = this.$options.filters.currency(
          price.value,
          price.baseCurrency
        );
      } else {
        formattedPrice = 0;
      }

      return `${formattedPrice} / ${this.typePass}`;
    },

    getPrice(price) {
      return price ? price.value : 0;
    },
  },

  watch: {
    passes: {
      deep: true,
      handler(newPasses) {
        this.internalPasses = newPasses;
      },
    },
  },
};
</script>
