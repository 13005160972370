<template>
  <div>
    <AODatePicker
      :title="$t('advica.date_range.title_entry')"
      data-cy="date-of-entry-picker"
      datePickerId="entry"
      v-model="startDate"
      :value="startDate"
      @clear="onDateClear"
      color="primary"
      class="mb-6"
      :current-date="currentDate"
      :initialDate="initialDate"
      :flight-arrival-date="flightArrivalDate"
      :dest-country-code="destCountryCode"
    />
    <AODatePicker
      :title="$t('advica.date_range.title_departure')"
      data-cy="date-of-departure-picker"
      datePickerId="departure"
      color="primary"
      ref="departureDatePicker"
      @change="onDateSelected"
      v-model="startDate"
      range
      :value="startDate"
      :current-date="currentDate"
      :initialDate="initialDate"
      :flight-arrival-date="flightArrivalDate"
      :dest-country-code="destCountryCode"
    />
  </div>
</template>

<script>
import AODatePicker from './AODatePicker';
import moment from 'moment';
import SelectedContextFlightMixins from '@/modules/flights/mixins/SelectedContextFlightMixins';

export default {
  name: 'AdvicaDateRangePicker',
  components: {
    AODatePicker,
  },
  mixins: [SelectedContextFlightMixins],
  props: {
    selectedDateRange: Array,
  },
  data() {
    return {
      initialDate: '',
      currentDate: '',
      startDate: [],
      flightArrivalDate: '',
      destCountryCode: '',
    };
  },
  methods: {
    onDateSelected(selectedDate) {
      this.$emit('change', selectedDate);
    },
    onDateClear() {
      if (this.$refs.departureDatePicker) {
        this.$refs.departureDatePicker.removeSelectedValue();
        this.$emit('clear');
      }
    },
  },
  watch: {
    arrivalTime: {
      immediate: true, // this will make it gets called on the first initilization which is equivalent to making the call in the mounted hook
      handler: function(val, oldVal) {
        if (val) {
          this.flightArrivalDate = val;
          this.destCountryCode = this.selectedFlightDestination.countryCode;
        }
      },
    },
  },
  mounted() {
    this.currentDate = moment().format('YYYY-MM-DD');
    this.initialDate = moment(this.currentDate)
      .add(48, 'hours')
      .format('YYYY-MM-DD')
      .toString();
  },
};
</script>
